//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import emailjs from 'emailjs-com';



export default {
  data() {
    return {
      uniacid:parseInt(window.localStorage.getItem('uniacid'))  , // get uniacid from local storage
      tabBarIndex: 0,
      tabBarList: [
        { label: 'ALL', params: {} },
        { label: 'addBooking', params: { tab: 'addBooking' } }
      ],
      total: -1,
      page: 1,
      form: {
        date: '',
        name: '',
        mobile: '',
        book_date: '',
        book_time: '',
        book_type: '',
        email: ''
      },
      bookingList: [],
      days: [],
      booking_time_list: [],
      booking_num_list: [],
      editDialogVisible: false,
      editForm: {
        book_date: '',
        book_time: '',
        book_type: '',
        mobile: '',
        name: ''
      },
      editingBookingId: null
    };
  },

  created() {
    this.getBookingList();
    const today = new Date();
    for (let i = 1; i < 15; i++) {
      const day = new Date(today.getTime() + 24 * 60 * 60 * 1000 * i);
      this.days.push(day);
    }
  },

  methods: {
    async submitForm() {
      console.log('date:', this.form.date);
      console.log('name:', this.form.name);
      console.log('mobile:', this.form.mobile);
      console.log("submitted");
      console.log('userid:', window.localStorage.getItem('user_id'));

      const uniacid = parseInt(window.localStorage.getItem('uniacid'));
      console.log('uniacid:', uniacid);
      const addform = {
        book_date: this.form.book_date,
        book_time: this.form.book_time,
        book_type: this.form.book_type,
        name: this.form.name,
        mobile: this.form.mobile,
        uniacid: uniacid,
        member_id: window.localStorage.getItem('user_id')
      };

      const api = '/booking/add';
      try {
        const res = await this.$http.post(api, addform);
        console.log('API Response:', res);

        const responseData = res;

        if (responseData.code === 0) {
          this.$message({ message: responseData, type: 'warning' });
        } else {
          this.$message.error(responseData);
        }

        if (responseData.code == 1 || responseData.code == 0) {
          const emaillist = [
            { uniacid: 17, service: 'service_i62illp', template: 'template_qo5jkxr', api: 'S3LnfEKwwhrsakUQT' },
            { uniacid: 19, service: 'service_vwa1m07', template: 'template_xgz76kk', api: 'H_8c6zkSntZyBRybB' },
            { uniacid: 22, service: 'service_065c0an', template: 'template_j3nk7ua', api: 'Xa1CTNFWE-reiybrI' },
            { uniacid: 20, service: 'service_eqgpjwl', template: 'template_ja1w4gg', api: 'aeuqp67xbb8BVViRT' },
            { uniacid: 24, service: 'service_yac630i', template: 'template_rjjp14v', api: '4wKkS4gd0Zd4UF-Dv' },
            { uniacid: 27, service: 'service_dxwctun', template: 'template_23tg4a9', api: 'na9x4syMmIN3PUVmw' },
            { uniacid: 33, service: 'service_zhkh12h', template: 'template_2mqr0tb', api: 'cuOUZQcP3XeoFq8J_' },
            { uniacid: 43, service: 'service_fi7ac9m', template: 'template_gt20suu', api: 'FrgSZsm9yHlRrMn0d' }
          ];

          const data = emaillist.find(item => item.uniacid === uniacid);

          if (!data) {
            console.log('No matching email service configuration found.');
            this.$message({ message: '配置未找到', type: 'error' });
            return;
          }

          const emailService = data.service;
          const emailTemplate = data.template;
          const emailApi = data.api;

          try {
            console.log('SEND MAIL');
            emailjs.send(emailService, emailTemplate, {
              book_date: addform.book_date,
              book_time: addform.book_time,
              book_type: addform.book_type,
              name: addform.name,
              mobile: addform.mobile,
              email: this.form.email,
              message: responseData.message
            }, emailApi)
            .then((result) => {
              alert('Email is sent to your input field to confirm status', result.text);
            }, (error) => {
              console.log('FAILED...', error.text);
            });
          } catch (error) {
            console.log('error', error);
            this.$message({ message: '發生錯誤', type: 'error' });
          }
        }
      } catch (error) {
        this.$message.error('API调用失败');
        console.error('API Error:', error);
      }
    },

  //   async getBookingList() {
  //     const uniacid = parseInt(window.localStorage.getItem('uniacid'));
  //     const res = await this.$http.get('/booking', {
  //       params: {
  //         page: this.page,
  //         size: 1000,
  //         uniacid: uniacid,
  //         member_id: window.localStorage.getItem('user_id')
  //       }
  //     });
  //     if (res.data.code !== 0) {
  //       return this.$message.error(res.data.message);
  //     }
  //     this.bookingList = res.data.list;
  //     console.log('bookingList: ', this.bookingList);

  //     const res2 = await this.$http.get('/bookingtime', {
  //       params: { uniacid: uniacid }
  //     });
  //     if (res2.data.code !== 0) {
  //       console.log(res2);
  //       return this.$message.error(res2.data.message);
  //     }

  //     console.log('booking time: ', res2.data.list);

  //     const res3 = await this.$http.get('/booking/getnum', {
  //       params: { uniacid: uniacid }
  //     });
  //     if (res3.data.code !== 0) {
  //       console.log(res3);
  //       return this.$message.error(res3.data.message);
  //     }

  //     console.log('booking getnum: ', res3.data.list);
  //   }
  // },
  async getBookingList() {
      try {
        const currentUniacid = parseInt(window.localStorage.getItem('uniacid'));
        const response = await fetch('https://sk.skeasy.info/v1/booking?params=%7B"page":1,"size":1000,"uniacid":24,"member_id":"580340166401576962"%7D');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data.code === 0 && data.data && data.data.list) {
          this.bookingList = data.data.list.filter(booking => booking.uniacid === currentUniacid);
        } else {
          console.error('Unexpected data format:', data);
        }
        console.log('Fetched booking list:', this.bookingList);
      } catch (error) {
        console.error('Error fetching data:', error);
        this.$message.error('Failed to fetch booking list');
      }
    },

      editBooking(item) {
      this.editingBookingId = item.id;
      this.editForm = {
        book_date: item.book_date,
        book_time: item.book_time,
        book_type: item.book_type,
        mobile: item.mobile,
        name: item.name
      };
      this.editDialogVisible = true;
    },

    async saveEditedBooking() {
      try {
        // Here you would typically make an API call to update the booking
        // For this example, we'll just update the local list
        const index = this.bookingList.findIndex(booking => booking.id === this.editingBookingId);
        if (index !== -1) {
          this.bookingList[index] = { ...this.bookingList[index], ...this.editForm };
          this.$message.success(this.$t('Booking updated successfully'));
          this.editDialogVisible = false;
        } else {
          throw new Error('Booking not found');
        }
      } catch (error) {
        console.error('Error updating booking:', error);
        this.$message.error(this.$t('Failed to update booking'));
      }
    }
  },
  
  watch: {
    tabBarIndex(val) {
      this.bookingList = [];
      this.total = -1;
      this.page = 1;
      this.getBookingList();
    }
  }
};
